export const defaultValues = {
    getAnoComboboxData ( listLenght = 20 ) {
        const currDate = new Date().getFullYear();
        return Array.from({ length: listLenght }, (_, i) => (currDate - i).toString());
    },

    getMesComboboxData () {
        return [
            { id: "01", text: 'Janeiro' },
            { id: "02", text: 'Fevereiro' },
            { id: "03", text: 'Março' },
            { id: "04", text: 'Abril' },
            { id: "05", text: 'Maio' },
            { id: "06", text: 'Junho' },
            { id: "07", text: 'Julho' },
            { id: "08", text: 'Agosto' },
            { id: "09", text: 'Setembro' },
            { id: "10", text: 'Outubro' },
            { id: "11", text: 'Novembro' },
            { id: "12", text: 'Dezembro' },
        ]
    },

    getRamoAtividadeComboboxData () {
        return [
            { id: "0", text: 'TODOS' },
            { id: "1", text: 'COMÉRCIO' },
            { id: "2", text: 'INDÚSTRIA' },
            { id: "3", text: 'SERVIÇO' },
        ]
    },

    getRegimeTributarioComboboxData () {
        return [
            { id: "0", text: 'NENHUM' },
            { id: "1", text: 'MEI' },
            { id: "2", text: 'SIMPLES NACIONAL' },
            { id: "3", text: 'LUCRO PRESUMIDO' },
            { id: "4", text: 'LUCRO REAL' },
        ]
    },

    getTipoPessoaComboboxData () {
        return [
            { id: "0", text: 'TODOS' },
            { id: "1", text: 'FÍSICA' },
            { id: "2", text: 'JURÍDICA' },
            { id: "3", text: 'EXTERIOR' },
            { id: "4", text: 'PRODUTOR RURAL' },
            { id: "5", text: 'PRE CADASTRO' },
        ];
    },

    getModeloComboboxData () {
        return [
            { id: "0", text: "Todos" },
            { id: "2", text: "Notas Fiscais" },
            { id: "1", text: "Notas Fiscais Série 2" },
            { id: "3", text: "NF-e / NFC-e Autorizadas" },
            { id: "4", text: "NF-e / NFC-e Não Autorizadas" },
            { id: "7", text: "NFS-e Autorizadas" },
            { id: "8", text: "NFS-e Não Autorizadas" },
            { id: "5", text: "Orçamento" },
            { id: "6", text: "CT-e" }
          ];
    },

}