const RelatoriosRoutes = [
  {
    path: "/relatoriovendadeservicos",
    name: "RelatorioVendaDeServicos",
    meta: {
      requiresAuth: true,
      title: "Relátorio de Venda de Serviços por Período"
    },
    component: () => import( "@/views/relatorios/RelatorioVendaDeServicos.vue" )
  },
  {
    path: "/relatoriovendadeprodutos",
    name: "RelatorioVendaDeProdutos",
    meta: {
      requiresAuth: true,
      title: "Relátorio de Venda de Produtos por Período"
    },
    component: () => import( "@/views/relatorios/RelatorioVendaDeProdutos.vue" )
  },
  {
    path: "/relatoriocurvadeprodutos",
    name: "RelatorioCurvaDeProdutos",
    meta: {
      requiresAuth: true,
      title: "Relatório Curva de Produtos"
    },
    component: () => import( "@/views/relatorios/RelatorioCurvaDeProdutos.vue" )
  },
  {
    path: "/relatoriodevendas",
    name: "RelatorioDeVendas",
    meta: {
      requiresAuth: true,
      title: "Relatório de Vendas"
    },
    component: () => import( "@/views/relatorios/RelatorioDeVendas.vue" )
  },
  {
    path: "/relatorioderecebimentos",
    name: "RelatorioRecebimentos",
    meta: {
      requiresAuth: true,
      title: "Relatório de Recebimentos"
    },
    component: () => import( "@/views/relatorios/RelatorioRecebimentos.vue" )
  },
  {
    path: "/relatorioderequisicoes",
    name: "RelatorioDeRequisicoes",
    meta: {
      requiresAuth: true,
      title: "Relatório de Requisições"
    },
    component: () => import( "@/views/relatorios/RelatorioDeRequisicoes.vue" )
  },
  {
    path: "/relatoriodeosporsetor",
    name: "RelatorioDeOSporSetor",
    meta: {
      requiresAuth: true,
      title: "Relatório de OS por Setor"
    },
    component: () => import( "@/views/relatorios/RelatorioDeOSporSetor.vue" )
  },
  {
    path: "/relatorioordemdeservico",
    name: "RelatorioOrdemDeServico",
    meta: {
      requiresAuth: true,
      title: "Relatório Ordem de Serviço"
    },
    component: () => import( "@/views/relatorios/RelatorioOrdemDeServico.vue" )
  },
  {
    path: "/relatoriosfaturamentocontabil",
    name: "RelatoriosFaturamentoContabil",
    meta: {
      requiresAuth: true,
      title: "Relatórios de Faturamento Contabil",
    },
    component: () => import( "@/views/relatorios/RelatorioFaturamentoContabil.vue" )
  },
  {
    path: "/relatoriosclientes",
    name: "RelatoriosClientes",
    meta: {
      requiresAuth: true,
      title: "Relatórios de Clientes",
    },
    component: () => import( "@/views/relatorios/RelatorioClientes.vue" )
  },
  {
    path: "/relatorioscurvadeclientes",
    name: "RelatoriosCurvaDeClientes",
    meta: {
      requiresAuth: true,
      title: "Relatórios Curva de Clientes",
    },
    component: () => import( "@/views/relatorios/RelatorioCurvaDeClientes.vue" )
  },
  {
    path: "/relatorioscurvaderepresentantes",
    name: "RelatoriosCurvaDeRepresentantes",
    meta: {
      requiresAuth: true,
      title: "Relatórios Curva de Representantes",
    },
    component: () => import( "@/views/relatorios/RelatorioCurvaDeRepresentantes.vue" )
  },
  {
    path: "/relatorioscurvadegrupos",
    name: "RelatoriosCurvaDeGrupos",
    meta: {
      requiresAuth: true,
      title: "Relatórios Curva de Grupos/Sub-Grupos",
    },
    component: () => import( "@/views/relatorios/RelatorioCurvaDeGrupos.vue" )
  },
  {
    path: "/relatoriosfornecedores",
    name: "RelatoriosFornecedores",
    meta: {
      requiresAuth: true,
      title: "Relatórios Fornecedores",
    },
    component: () => import( "@/views/relatorios/RelatorioFornecedores.vue" )
  },
  {
    path: "/relatorioscomissao",
    name: "RelatoriosComissao",
    meta: {
      requiresAuth: true,
      title: "Relatórios Comissão",
    },
    component: () => import( "@/views/relatorios/RelatorioComissao.vue" )
  },
  {
    path: "/relatoriosmovimentacaoestoque",
    name: "RelatoriosMovimentacaoEstoque",
    meta: {
      requiresAuth: true,
      title: "Relatorios de Movimentação de Estoque",
    },
    component: () => import( "@/views/relatorios/RelatorioMovimentacaoEstoque.vue" )
  },
  {
    path: "/relatoriohistoricoveicular",
    name: "RelatoriosHistoricoVeicular",
    meta: {
      requiresAuth: true,
      title: "Relatorios de Movimentação de Estoque",
    },
    component: () => import( "@/views/relatorios/RelatorioHistoricoVeicular.vue" )
  },
]

export default RelatoriosRoutes
