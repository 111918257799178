export const func = {

  calculaIdade: (req) => {
    let dataNascimento = req.split("/");
    let dataAtual = new Date()
      .toLocaleString("pt-BR")
      .split(" ")[0]
      .split("/");
    var diaAtual = parseInt(dataAtual[0]);
    var mesAtual = parseInt(dataAtual[1]);
    var anoAtual = parseInt(dataAtual[2]);
    var diaNascimento = parseInt(dataNascimento[0]);
    var mesNascimento = parseInt(dataNascimento[1]);
    var anoNascimento = parseInt(dataNascimento[2]);
    var result = diaNascimento > diaAtual && mesNascimento >= mesAtual
      ? anoAtual - anoNascimento - 1
      : anoAtual - anoNascimento;
      if(result < 0){
        result = 0
      }
    return result;
  },
  convertDate: (date) => {

    return new Date(date + ' 00:00:00').toLocaleString('pt-BR').split(',')[0];
  },

  transformeMedia: (media) => {
    return media.toLocaleString('pt-BR', {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  },

  convertMoney: (money) => {

    if (!money) {
      let a = 0;
      return a.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    }
    money = parseFloat(money)
    if (money.toString().includes('.')) {
      return money.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    }
    else {
      return money.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    }
  },

  convertMoney2: (money, casas) => {
    const numberCasas = casas || 2;
    if (!money) {
      let a = 0;
      return a.toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    }
    if (money?.toString().includes(',')) {
      money = money?.replace(',', '.');
    }
    return parseFloat(money).toLocaleString('pt-br',
      { minimumFractionDigits: numberCasas, maximumFractionDigits: numberCasas });
  },


  convertDateTime: (date) => {
    if (!date) {
      return ""
    }
    if (date.toString().includes("T")) {
      return new Date(date.split("T")[0] + ' 00:00:00').toLocaleString('pt-BR').split(',')[0]
    }
    else if (date.toString().includes(" ") == true) {
      if (date.split(' ')[0].split('-')[2].length == 4) {
        return new Date(date.split(' ')[0].split('-').reverse().join('-') + ' 00:00:00').toLocaleString('pt-BR').split(',')[0]
      }
      else {

        return new Date(date + ' 00:00:00').toLocaleString('pt-BR').split(',')[0];
      }
    }
    else if (date.toString().includes("/") == true) {
      if (date.split('/')[2].length == 4) {
        return new Date(date.split('/').reverse().join('-') + ' 00:00:00').toLocaleString('pt-BR').split(',')[0];
      }
    }

    else if (date.toString().includes("-") && date.toString().split('-')[0].length == 4) {
      return new Date(date + ' 00:00:00').toLocaleString('pt-BR').split(',')[0];
    }
    else {

      return new Date(date.split('-').reverse().join('-') + ' 00:00:00').toLocaleString('pt-BR').split(',')[0];
    }
  },
  getCurrentDate: () => {
    return new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString().slice(0, 19).replace('T', ',')
  },
  alterJSONArchive: () => {
    const fs = require('fs');
    const env = process.env.VUE_APP_BACKEND;
    const data = env;
    const json = data;
    const modifiedData = JSON.stringify(json);
    fs.writeFileSync('@/config/env.json', modifiedData);

  },
  convertTime: (date) => {
    if (date) {
      var dataUTCConvert1 = new Date(date);
      var hour = dataUTCConvert1.getHours();
      var min = dataUTCConvert1.getMinutes();
      if (hour < 10) {
        hour = "0" + hour;
      }
      if (min < 10) {
        min = "0" + min;
      }

      return hour + ":" + min;
    }
    return '00:00';
  },
  removeAcento: (data) => {
    data = data.toLowerCase();
    data = data.replace(new RegExp('[ÁÀÂÃ]', 'gi'), 'a');
    data = data.replace(new RegExp('[ÉÈÊ]', 'gi'), 'e');
    data = data.replace(new RegExp('[ÍÌÎ]', 'gi'), 'i');
    data = data.replace(new RegExp('[ÓÒÔÕ]', 'gi'), 'o');
    data = data.replace(new RegExp('[ÚÙÛ]', 'gi'), 'u');
    data = data.replace(new RegExp('[Ç]', 'gi'), 'c');
    return data.toUpperCase();
  },
  isMobile: () => {
    return screen.width <= 600 ? true : false;
  },
  colorRow: ( item ) => {
    if ( item.color ) {
      return item.color;
    } else if ( item.ORDEM == 0 ) {
      return "colortables"; //can also return multiple classes e.g ["orange","disabled"]
    } else {
      return 'colorwhite'
    }
  },
  hexToRgb(hex) {
    // Remova o caractere '#' (se existir)
    hex = hex.replace('#', '');
    // Verifica se o valor hexadecimal é válido
    if (!/^[0-9A-F]{6}$/i.test(hex)) {
      throw new Error('Valor hexadecimal inválido.');
    }
    // Converta o valor hexadecimal para RGB
    var r = parseInt(hex.substring(0, 2), 16);
    var g = parseInt(hex.substring(2, 4), 16);
    var b = parseInt(hex.substring(4, 6), 16);
    // Retorne o valor RGB como uma string
    return 'RGB(' + r + ', ' + g + ', ' + b + ')';
  },
  convertToApiDate: (item) => {
    if (!item) {
      return '';
    }
    return item.split('/').reverse().join('-');
  },
  verifyValidDate: (item) => {
    if (!item) {
      return false;
    }
    var dateParts = item.split('/');
    var day = parseInt(dateParts[0], 10);
    var month = parseInt(dateParts[1], 10) - 1;
    var year = parseInt(dateParts[2], 10);

    var date = new Date(year, month, day);

    // Verifica se o ano, mês e dia correspondem aos valores fornecidos
    // Também verifica se o objeto de data criado é válido
    if (
      date.getDate() === day &&
      date.getMonth() === month &&
      date.getFullYear() === year
    ) {
      return true; // A data é válida
    } else {
      return false; // A data é inválida
    }
  },
  verificaString: (item) => {
    return item ? item : '';
  },
  convertHour: (item) => {
    if (!item) {
      return '00:00';
    }
    const time = new Date(item).toLocaleTimeString();
    const [hour, minute] = time.split(':')
    return hour + ':' + minute;
  },
  ComboboxContainer(inputElement) {
    // Procura pelo contêiner do combobox subindo até cinco níveis na hierarquia dos pais
    let currentElement = inputElement;
    for (let i = 0; i < 12; i++) {
      if (currentElement && currentElement.classList && currentElement.classList.contains('v-input__control')) {
        return currentElement;
      }
      currentElement = currentElement.parentElement;
    }
    return null; // Retorna null se nenhum contêiner do combobox for encontrado
  },
  getLabel(comboboxContainer) {
    // Verifica se há um elemento com a classe específica do Vuetify para rótulo
    const labelElement = comboboxContainer.querySelector('.v-label');
    if (labelElement) {
      return labelElement.innerText.trim();
    }
    // Se nenhum rótulo for encontrado, retorne uma string vazia
    return '';
  },
  getDateAtual() {
    const date = new Date();
    const ano = date.getFullYear();
    const mes = String(date.getMonth() + 1).padStart(2, "0");
    const dia = String(date.getDate()).padStart(2, "0");
    const dataFormatada = `${ano}-${mes}-${dia}`;
    return dataFormatada;
  },
  getDateAtualFomated () {
    const date = new Date();
    const ano = date.getFullYear();
    const mes = String(date.getMonth() + 1).padStart(2, "0");
    const dia = String(date.getDate()).padStart(2, "0");
    const dataFormatada = `${dia}/${mes}/${ano}`;
    return dataFormatada;
  },
  getHoraAtual() {
    const date = new Date();
    const hora = String(date.getHours()).padStart(2, "0");
    const minutos = String(date.getMinutes()).padStart(2, "0");
    const segundos = String(date.getSeconds()).padStart(2, "0");
    const horaFormatada = `${hora}:${minutos}:${segundos}`;
    return horaFormatada;
  },
  getToApiDate ( item ) {
    if ( !item ) return "";
    const dataOriginal = new Date( item );
    const dia = dataOriginal.getDate();
    const mes = dataOriginal.getMonth() + 1;
    const ano = dataOriginal.getFullYear();
    const dataFormatada = `${ dia }/${ mes < 10 ? '0' + mes : mes }/${ ano }`;
    return dataFormatada;
  },
  verificaObjeto(obj1, obj2) {
    // Verifica se são objetos
    if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
      return obj1 === obj2;
    }
    // Obtém as chaves dos objetos
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
    // Verifica se têm a mesma quantidade de chaves
    if (keys1.length !== keys2.length) {
      return false;
    }
    // Itera sobre as chaves e compara recursivamente os valores
    for (const key of keys1) {
      if (!this.verificaObjeto(obj1[key], obj2[key])) {
        return false;
      }
    }
    return true;
  },
  handleResponse ( req ) {
    const { data, fields, pagination, isMobile } = req;
    let result;
    if ( data.code === 500 ) {
      if ( !isMobile ) {
        result = [];
      }
    } else {
      if ( isMobile && fields?.length > 0 && pagination > 0 ) {
        result = [ ...fields, ...data ];
        result.total = data.total;
      } else {
        result = data;
      }
    }
    return result;
  },
  openWhatsapp(phone) {
    if (!phone) {
      throw("Número Inválido ou não encontrado!")
    }
    const numero = phone.replace(/[^0-9]/g, "");
    window.open(`https://api.whatsapp.com/send?phone=55${numero}`, "_blank");
  },
  getFutureDate(req){
    let { date, days } = req || {};
    if(!days){
      return ''
    }
    let initialDate = date ? new Date(date + ' 00:00:00') : new Date();
    initialDate.setDate(initialDate.getDate() + days);
    let futureDay = String(initialDate.getDate()).padStart(2, '0');
    let futureMonth = String(initialDate.getMonth() + 1).padStart(2, '0');
    let futureYear = initialDate.getFullYear();
    return {date: `${futureYear}-${futureMonth}-${futureDay}`, dateFormated: `${futureDay}/${futureMonth}/${futureYear}` }

  },
  dynamicStringFitting(strings, separator, maxLength = 90, stringByLine = 2, linesLimit = 3) {
    // Concatena uma lista de String dinamicamente conforme o tamanho.
    // Limita o comprimento da linha pelo maxLength e concatena as string até encher uma linha.
    // Retorna uma lista de linhas de Strings concatenadas.
    const stringList = [...strings];
    const result = [];
    let currentGroup = "";
  
    while (stringList.length > 0) {
      let currentSum = 0;
      let penalty = 0;
      currentGroup = ""; 
      let stringsInCurrentGroup = 0;

      // Calcula o tamanho das Strings concatenadas.
      for (let i = 0; i < stringList.length; i++) {
        const currentString = stringList[i];
        const currentLength = currentString.length;
        const totalWithPenalty = currentSum + penalty + currentLength;
  
        // Valida se as strings concatenadas tem comprimento maior que o limite e concatena caso possível.
        if (totalWithPenalty <= maxLength && stringsInCurrentGroup < stringByLine) {
          currentGroup += (currentGroup ? separator : "") + currentString;
          currentSum += currentLength;
          penalty = (currentGroup.split(separator).length - 1) * separator.length;
  
          stringList.splice(i, 1);
          stringsInCurrentGroup++;
          i--;
        } else { // Valida se ainda existem strings que possam ser concatenadas.
          const difference = maxLength - (currentSum + penalty);
          const smallestRemainingLength = Math.min(...stringList.map(s => s.length));
          if (difference >= 0 && difference < smallestRemainingLength) {
            break; // Finaliza o processo de concatenação, caso não tenha mais o que concatenar sem passar do limite.
          }
        }
      }
  
      // Adiciona a linha concatenada no resultado, respeitando o limite de linhas máximo.
      if (currentGroup) {
        result.push(currentGroup);
        if (result.length >= linesLimit) break;
      }
    }
  
    return result;
  },

  capitalizeText(stringText, separator = " ", onlyFirst = false) {
    // Capitaliza o texto para usar nas Labels dos formulários.
    // Se passar onlyFirst=true, capitaliza apenas a primeira palavra.
    if (!stringText) return "";

    const words = stringText.split(separator);

    let capitalizedWords;
    if (onlyFirst) {
      capitalizedWords = [
        words[0].charAt(0).toUpperCase() + words[0].slice(1).toLowerCase(),
        ...words.slice(1).map(word => word.toLowerCase())
      ];
    } else {
      capitalizedWords = words.map(
        word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
      );
    }

    return capitalizedWords.join(separator);
  },

  // Corrige palavras mapeadas para usar dinamicamente em labels ou inputs.
  // Se passada mais de uma palavra ou identificando algum dos delimitadores.
  // Separa as palavras e aplica a correção.
  correctMappedWords(text) { 
    if (!text) return "";
  
    const wordMapping = {
      'PERIODO': 'PERÍODO',
      'HISTORICO': 'HISTÓRICO',
      'ANALITICO': 'ANALÍTICO',
      'SINTETICO': 'SINTÉTICO'
    };
  
    const delimiters = [' ', '-', '_', ',', '.', '/', '\\', '|'];
  
    // Preserva a estrutura original do texto (maiúsculas e minúsculas).
    const preserveCase = (original, replacement) => {
      if (original === original.toUpperCase()) return replacement.toUpperCase();
      if (original === original.charAt(0).toUpperCase() + original.slice(1).toLowerCase()) {
        return replacement.charAt(0).toUpperCase() + replacement.slice(1).toLowerCase();
      }
      return replacement.toLowerCase();
    };
  
    // Substitui todas as palavras encontradas dentro do texto, conforme palavras mapeadas.
    const replaceWordsInText = (text) => {
      let modifiedText = text;
      let hasReplacements;
  
      do {
        hasReplacements = false;
        for (const [key, replacement] of Object.entries(wordMapping)) {
          const regex = new RegExp(key, 'gi'); // Procura pela chave (case insensitive).
          if (regex.test(modifiedText)) {
            modifiedText = modifiedText.replace(regex, match => preserveCase(match, replacement));
            hasReplacements = true;
          }
        }
      } while (hasReplacements);
  
      return modifiedText;
    };
  
    // Se não houver delimitadores, substitui diretamente as palavras.
    if (!delimiters.some(delim => text.includes(delim))) {
      return replaceWordsInText(text);
    }
  
    // Divide com base nos delimitadores e processa cada parte separadamente.
    const splitRegex = new RegExp(`([${delimiters.map(d => `\\${d}`).join('')}])`, 'g');
    return text
      .split(splitRegex) // Divide pelo regex mantendo os delimitadores
      .map(part => (delimiters.includes(part) ? part : replaceWordsInText(part)))
      .join(""); // Junta as partes preservando os delimitadores
  },

  // Usado em campos como Observações que tem várias linhas para tratar as quebras de linha.
  handleLinebreakText(array, text, {dataColumn, labelColumn = null, prefix = "", linha = true}) {
    // Ajusta quebras de linha pra adicionar espaço adequado nas tabelas.
    if (text && (text.includes('\n') || text.includes('\r'))) { 
      const lines = text.split(/\r\n|\n|\r/);

      lines.forEach((line, index) => {
        const entry = {
              [dataColumn]: line,
              bold: false,
              linha: linha ? index === lines.length - 1 : false,
          };

          if (labelColumn) {
              entry[labelColumn] = index === 0 ? prefix : "";
          } else {
              entry[dataColumn] = index === 0 ? prefix + line : line;
          }
          array.push(entry);
      });
    } else { 
      // Observações com uma linha ou nenhuma linha.
      if (labelColumn) {
        array.push({
          [labelColumn]: prefix,
          [dataColumn]: `${text || ''}`,
          linha: linha,
        });
      } else {
        array.push({
          [dataColumn]: `${prefix}${text || ''}`,
          linha: linha,
        });
      }
      }
  },
  

} 