import jsPDF from "jspdf";
const env = process.env.VUE_APP_BACKEND;
import bsImage from '../assets/bs.png';
import { utils, writeFile } from 'xlsx';
export function generatePDF ( req ) {
  const { orientacao, usuario, empresa, cabecalho, headers, body, footer, type } = req;
  function criarCabecalho ( doc ) {
    x = 10; y = 10;
    const imageUrl = empresa.EMP_IMG_LOGO_REL ? `${ env }/uploads/${ empresa.id }/${ empresa.EMP_IMG_LOGO_REL }` : null;
    if ( imageUrl ) doc.addImage( imageUrl, 'JPEG', x, y, 45, 17 );
    doc.line( x, y, max_width, y );
    doc.line( x, y, x, y + 17 );
    doc.line( x, y + 17, max_width, y + 17 );
    doc.line( max_width, y, max_width, y + 17 );
    doc.line( x += 45, y, x, y + 17 );
    x = ( max_width + 10 ) / 2;
    doc.setFontSize( cabecalho.title.tamanho || 8 );
    doc.setFont( "Helvetica", cabecalho.title.bold ? "bold" : "normal" );
    doc.text( cabecalho.title.text, x, cabecalho.title.y, { align: "center" });
    if ( cabecalho.linha2 ) {
      doc.setFontSize( cabecalho.linha2.tamanho || 8 );
      doc.setFont( "Helvetica", cabecalho.linha2.bold ? "bold" : "normal" );
      doc.text( cabecalho.linha2.text, x, cabecalho.linha2.y, { align: "center" });
      if ( cabecalho.linha3 ) {
        doc.setFontSize( cabecalho.linha3.tamanho || 8 );
        doc.setFont( "Helvetica", cabecalho.linha3.bold ? "bold" : "normal" );
        doc.text( cabecalho.linha3.text, x, cabecalho.linha3.y, { align: "center" });
        if ( cabecalho.linha4 ) {
          doc.setFontSize( cabecalho.linha4.tamanho || 8 );
          doc.setFont( "Helvetica", cabecalho.linha4.bold ? "bold" : "normal" );
          doc.text( cabecalho.linha4.text, x, cabecalho.linha4.y, { align: "center" });
        }
      }
    }
    x = max_width - 45;
    doc.line( x, y, x, y + 17 );
    doc.setFont( undefined, "italic" );
    doc.setFontSize( 8 );
    doc.text( "Usuário:", x += 1, y + 3, { align: "left" });
    doc.text( "Data:", x, y + 6.5, { align: "left" });
    doc.text( "Hora:", x, y + 10, { align: "left" });
    doc.addImage( bsImage, "PNG", x, y + 11 );
    doc.setFont( undefined, "normal" );
    doc.text( `${ usuario }`, max_width - 1, y + 3, { align: "right" });
    const dataHoraAtual = new Date();
    const date = dataHoraAtual.toLocaleDateString();
    const hora = dataHoraAtual.toLocaleTimeString();
    doc.text( date, max_width - 1, y + 6.5, { align: "right" });
    doc.text( hora, max_width - 1, y + 10, { align: "right" });
  }
  function criarHeader ( doc ) {
    x = 10; y = 27;
    if ( headers.mostrar != false ) {
      doc.setFillColor( 209, 209, 209 );
      doc.rect( x + 0.3, y + 0.3, max_width - 10.6, 4.4, "F" );
      doc.line( x, y, x, y + 5 );
      doc.line( max_width, y, max_width, y + 5 );
      doc.line( x, y + 5, max_width, y + 5 );
    }
    doc.setFont( "Helvetica", "bold" );
    doc.setFontSize( 8 );
    let max = max_width - 10;
    let item_com_espaco = 0;
    headers.dados.forEach( item => {
      if ( item.espaco ) {
        max -= item.espaco;
        item_com_espaco++;
      }
    });
    let espacamento = max / ( headers.dados.length - item_com_espaco );
    headers.dados.forEach( item => {
      item.x = x + 1;
      if ( headers.mostrar != false ) {
        doc.text( item.text || "", item.x, y + 4 );
        if ( item.linha ) doc.line( item.x - 1, y, item.x - 1, y + 5 );
      } 
      if ( item.espaco ) x += item.espaco;
      else x += espacamento;
    });
  }
  function criarBody ( doc ) {
    x = 10;
    y = headers.mostrar != false ? 32 : 27;
    let x_align = null;
    let y_align = null;
    let y_linha = null;
    const fillColors = [ "#FFFFFF", "#f2f2f2" ];
    let quantas_linhas = [1];
    const breakTextIntoLines = ( text, maxWidth ) => {
      let lines = [];
      let currentLine = '';
      let words = text.split(' ');
      for ( let word of words ) {
        let testLine = currentLine ? currentLine + ' ' + word : word;
        let testWidth = doc.getTextDimensions( testLine ).w;
        if ( testWidth > maxWidth ) {
          if ( currentLine ) lines.push( currentLine );
          currentLine = word;
        } else {
          currentLine = testLine;
        }
      }
      if ( currentLine ) lines.push( currentLine );
      return lines;
    };
    body.forEach(( item, index ) => {
      y += 3;
      y_align = y;
      quantas_linhas = [1];
      headers.dados.forEach(( header, header_index ) => {
        const proximo_header = headers.dados[ header_index + 1 ];
        const x_proximo_header = proximo_header ? proximo_header.x - 2 : max_width - 1;
        const max_descricao = x_proximo_header - header.x;
        let description = item[ header.value ] || "";
        if ( header.quebrar ) {
          let contagem = breakTextIntoLines( description, max_descricao );
          quantas_linhas.push( contagem.length );
        }
      });
      if ( item.color ) {
        if ( item.color == 1 || item.color == 0 ) doc.setFillColor( fillColors[ item.color ]);
        else doc.setFillColor( item.color );
        doc.rect( x + 0.3, y - 2.7, max_width - 10.6, 4 * Math.max( ...quantas_linhas ), "F" );
      }
      if ( item.header ) {
        doc.setFillColor( 209, 209, 209 );
        doc.rect( x + 0.3, y - 2.6, max_width - 10.6, 3.4 * Math.max( ...quantas_linhas ), "F" );
      }
      headers.dados.forEach(( header, header_index ) => {
        doc.setFont( "Helvetica", header.bold || item.bold ? "bold" : "normal" );
        doc.setFontSize( header.tamanho_fonte || 7 );
        const proximo_header = headers.dados[ header_index + 1 ];
        const x_proximo_header = proximo_header ? proximo_header.x - 2 : max_width - 1;
        const align = item.align || header.align_items || "left";
        if ( align === "right" ) {
          x_align = x_proximo_header;
        } else if ( align === "center" ) {
          x_align = proximo_header ? ((( proximo_header.x - 2 ) - header.x ) / 2 ) + header.x - 1 : ((( max_width - 2 ) - header.x ) / 2 ) + header.x;
        } else {
          x_align = header.x;
        }
        const max_descricao = ( header.cortar_em ? header.cortar_em : x_proximo_header ) - header.x;
        let description = item[ header.value ] ? item[ header.value ].toString() : "";
        if ( header.cortar || header.cortar_em ) {
          const fitsInWidth = ( text ) => doc.getTextDimensions( text ).w <= max_descricao;
          if ( !fitsInWidth( description ) ) {
            let start = 0;
            let end = description.length;
            let mid;
            while ( start < end ) {
              mid = Math.floor(( start + end ) / 2 );
              if ( fitsInWidth( description.substring( 0, mid )))  start = mid + 1;
              else end = mid;
            }
            description = description.substring(  0, end - 3 ) + '...';
          }
          doc.text( description, x_align, y );
        } else if ( header.quebrar || header.quebrar_em ) {
          let lines = breakTextIntoLines( description, header.quebrar_em ? header.quebrar_em : max_descricao );
          for ( let line of lines ) {
            doc.text( line, x_align, y_align );
            y_align += 3;
          }
          y_align -= 3;
        } else {
          doc.text( description, x_align, y, { align: align });
        }
        if (  y_align == y ) y_linha = y + 1;
        else y_linha = y_align + 1;
        if ( (header.linha && item[ header.value ]) || header.value == item.linhaLateral ) doc.line( header.x - 1, y - 3, header.x - 1, y_linha );
      });
      if ( item.linha && index != body.length - 1 && y_linha <= max_height ) doc.line( 10, y_linha, max_width, y_linha );
      y = y_linha;
      if ( y + 4 > max_height ) {
        doc.line( x, headers.mostrar != false ? 32 : 27, x, y );
        doc.line( max_width, headers.mostrar != false ? 32 : 27, max_width, y );
        doc.line( x, y, max_width, y );
        doc.addPage();
        criarCabecalho( doc, empresa, cabecalho, usuario );
        if ( headers.mostrar != false ) criarHeader( doc );
        x = 10; y = headers.mostrar != false ? 32 : 27;
      }
    });
    doc.line( x, headers.mostrar != false ? 32 : 27, x, y );
    doc.line( max_width, headers.mostrar != false ? 32 : 27, max_width, y );
    doc.line( x, y, max_width, y );
  }
  function criarFooter ( doc ) {
    x = 10;
    if ( footer?.color ) {
      doc.setFillColor( 209, 209, 209 );
      doc.rect( x + 0.3, y + 0.3, max_width - 10.6, 4.4, "F" );
    }
    doc.line( x, y, x, y + 5 );
    doc.line( max_width, y, max_width, y + 5 );
    doc.line( x, y + 5, max_width, y + 5 );
    let max = max_width - 10;
    let item_com_espaco = 0;
    footer?.dados?.forEach( item => {
      if ( item.espaco ) {
        max -= item.espaco;
        item_com_espaco++;
      }
    });
    let espacamento = max / ( footer?.dados?.length - item_com_espaco );
    footer?.dados?.forEach( item => {
      item.x = x + 1;
      if ( item.linha ) doc.line( item.x - 1, y, item.x - 1, y + 5 );
      if ( item.espaco ) x += item.espaco;
      else x += espacamento;
    });
    footer?.dados?.forEach(( item, index ) => {
      const proximo_footer = footer?.dados[ index + 1 ];
      const x_proximo_footer = proximo_footer ? proximo_footer.x - 2 : max_width - 1;
      const align = item.align_items || "left";
      if ( align === "right" ) {
        x = x_proximo_footer;
      } else if ( align === "center" ) {
        x = proximo_footer ? ((( proximo_footer.x - 2 ) - item.x ) / 2 ) + item.x - 1 : ((( max_width - 2 ) - item.x ) / 2 ) + item.x;
      } else {
        x = item.x;
      }
      doc.setFont( "Helvetica", item.bold == false ? "normal" : "bold" );
      doc.setFontSize( item.tamanho || 7 );
      doc.text( item.text, x, y + 4, { align: align });
    });
  }
  const doc = new jsPDF({
    unit: "mm",
    format: "a4",
    orientation: orientacao == "vertical" ? "landscape" : "portrait",
  });
  doc.setDrawColor( 0, 0, 0 );
  doc.setTextColor( 0, 0, 0 );
  doc.setFont( "Helvetica", "normal" );
  doc.setFontSize( 8 );
  const max_height = doc.internal.pageSize.height - 10;
  const max_width = doc.internal.pageSize.width - 10;
  let y = 0;
  let x = 0;
  criarCabecalho( doc );
  criarHeader( doc );
  criarBody( doc );
  if ( footer ) criarFooter( doc );
  doc.setFont( 'Helvetica', 'normal' );
  doc.setFontSize( 8 );
  var totalPages = doc.internal.getNumberOfPages();
  for ( var i = 1; i <= totalPages; i++ ) {
    doc.setPage( i );
    doc.text( `Pág: ${ i } de ${ totalPages }`, max_width - 1, 26, { align: "right" });
  }
  let pdfBlob = doc.output('blob');
  if ( type == "iframe" ) {
    return URL.createObjectURL( pdfBlob );
  } else if ( type == "email" ) {
    return pdfBlob;
  } else {
    doc.save(`${ cabecalho.title.text.replace(/ /g, '_')}.pdf` );
  }
}
export function generateExcel ( req, title ) {
  const worksheet = utils.json_to_sheet( req );
  const workbook = { Sheets: { 'Sheet1': worksheet }, SheetNames: [ 'Sheet1' ]};
  writeFile( workbook, `${ title ? title.replace(/ /g, '_') : 'excel' }.xlsx` );
}