import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import VCalendar from 'v-calendar';
import router from './router'
import vuetify from './plugins/vuetify'
import frontConfig from './config/front';
import apiConfig from './config/api';
import apiRel from './config/api_rel';
import sessionConfig from './config/session';
import redisConfig from './config/redis';
import { VueMaskDirective } from 'v-mask'
import "./plugins/vuetify-mask.js";
import {func} from './functions/functions.js';
import {reqFunc} from './functions/requisitionFunctions.js';
import {defaultValues} from './functions/defaultValuesFunctions.js';
import {labelDict} from './functions/labelDictionary.js';
import {manageInputs} from './functions/manageInputs.js';
import { generatePDF, generateExcel } from './functions/generateRelatorio.js';
import getBlob from "@/functions/getBlob";
import Snotify, {SnotifyPosition} from 'vue-snotify';
import "vue-snotify/styles/material.css";
import store from './store/store'
const checkIsMobile = () => window.innerWidth <= 1264;
import { io } from "socket.io-client";
import {cript} from './functions/criptografia.js';
import 'vuetify/dist/vuetify.min.css';
import actionFunction from "@/functions/actionsFunctions.js"
Vue.prototype.$func = func;
Vue.prototype.$reqFunc = reqFunc;
Vue.prototype.$defaultValues = defaultValues;
Vue.prototype.$labelDict = labelDict;
Vue.prototype.$actionFunction = actionFunction;
Vue.prototype.$inputs = manageInputs;
Vue.prototype.$isMobile = checkIsMobile;
Vue.prototype.$updateIsMobile = () => {
  Vue.prototype.$isMobile = checkIsMobile();
};
Vue.prototype.$updateIsMobile();
window.addEventListener('resize', Vue.prototype.$updateIsMobile);
Vue.prototype.$generatePDF = generatePDF;
Vue.prototype.$generateExcel = generateExcel;
Vue.prototype.$getBlob = getBlob;
Vue.directive('mask', VueMaskDirective);
Vue.prototype.$cript = cript;
const socketUrl = process.env.VUE_APP_SOCKET_URL || 'http://localhost:1998';
Vue.prototype.$socket = io(socketUrl);
Vue.config.productionTip = false;
Vue.use(VCalendar, {
  componentPrefix: 'vc'  // Use <vc-calendar /> instead of <v-calendar /           // ...other defaults
});
const Snotifyoptions = {
  toast: {
    position: SnotifyPosition.rightTop,
    animation: { enter: 'fadeIn', exit: 'fadeOut', time: 400, translate: { y: '0%' } },
    closeOnClick: true,
  }
}
Vue.prototype.$sharedData = {
  redirecionar: true,
}

Vue.use(Snotify , Snotifyoptions);
Vue.config.silent = true;
new Vue({
  router,
  store,
  vuetify,
  frontConfig,
  sessionConfig,
  apiConfig,
  apiRel,
  redisConfig,
  render: h => h(App)
}).$mount('#app')

