import api from "@/config/api"
class CompraPagamentosController {
  async getLength ( req, res ) {
    try { 
      const total = await api.post( `/comprapagamentos/registros/${ req.EMP_ID }`, req );
      const response = res;
      response.total = total.data[ 0 ].TOT_REGISTROS;
      return response;
    } catch ( error ) {
      console.error( error || "Erro em CompraPagamentosController getLength." );
      throw  error
    }
  }
  async index ( req ) {
    try {
      const response = await api.post( `/comprapagamentos/filtros/${ req.EMP_ID }`, req );
      if ( response.data.code != 500 ) {
        response.data.forEach(( item, index ) => {
          item.ORDEM = index % 2 == 0 ? 0 : 1;
          item.id = item.CMPD_ID;
        });
      }
      return await this.getLength( req, response.data );
    } catch ( error ) {
      console.error( error || "Erro em CompraPagamentosController index." );
      throw error
    }
  }
  async create ( req ) {
    try {
      const response = await api.post( "/comprapagamentos", req );
      return response.data;
    } catch ( error ) {
      console.error( error || "Erro em CompraPagamentosController create." );
      throw error
    }
  }
  async update ( req ) {
    try {
      const response = await api.put( `/comprapagamentos/${ req.CMPD_ID }/${ req.CMPD_EMP_ID }`, req );
      return response.data;
    } catch ( error ) {
      console.error( error || "Erro em CompraPagamentosController update." );
      throw error
    }
  }
  async delete ( req ) {
    try {
      const response = await api.delete( `/comprapagamentos/${ req.CMPD_ID }/${ req.CMPD_EMP_ID }`);
      return response.data;
    } catch ( error ) {
      console.error( error || "Erro em CompraPagamentosController delete." );
      throw error
    }
  }
  async payments ( req ) {
    try {
      const response = await api.post( `/comprapagamentos/adiantamentos/${ req.FORN_ID }/${ req.BCO_ID }`, req );
      return response.data;
    } catch ( error ) {
      console.error( error || "Erro em CompraPagamentosController payments." );
      throw error
    }
  }
}

export default new CompraPagamentosController()
