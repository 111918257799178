import Vue from "vue"
import VueRouter from "vue-router"
import VueCookies from "vue-cookies"
import store from "@/store/store"
import api from "@/config/api"
import api_rel from "@/config/api_rel"
import { cript } from "@/functions/criptografia"

Vue.use( VueRouter )

import AuthenticationRoutes from "./routes/authenticationRoutes"

import homeRoutes from "./routes/homeRoutes"

import notFoundRoutes from './routes/notFoundRoutes'

import conexaoLocalRoutes from './routes/conexaoLocalRoutes'

import inteligenciaArtificial from './routes/inteligenciaArtificialRoutes'

import AtualizaBancoRoutes from "./routes/projetos/atualizaBancoRoutes"

import GerenciadorEmpresasRoutes from "./routes/projetos/gerenciadorEmpresasRoutes"

import TestesRoutes from "./routes/teste/TestesRoutes"

///////////////////////////////////////////////////////////////////////

import AgendaServicoRoutes from "./routes/agendaServicoRoutes"
import BancosRoutes from "./routes/bancosRoutes"
import BaixaContasPagarRoutes from "./routes/baixaContasPagarRoutes"
import CidadesRoutes from "./routes/cidadesRoutes"
import ClientesRoutes from "./routes/clientesRoutes"
import ColaboradoresRoutes from "./routes/colaboradoresRoutes"
import ComprasRoutes from "./routes/comprasRoutes"
import CondicionaisRoutes from "./routes/condicionaisRoutes"
import CondicoesVencimentoRoutes from "./routes/condicoesVencimentoRoutes"
import ConsultaProdutosRoutes from "./routes/consultaProdutosRoutes"
import ContasAReceberRoutes from "./routes/contasAReceberRoutes"
import CoresRoutes from "./routes/coresRoutes"
import CorrecoesEstoqueRoutes from "./routes/correcoesEstoqueRoutes"
import EmpresasRoutes from "./routes/empresasRoutes"
import EquipamentoRoutes from "./routes/equipamentoRoutes" // ajustar
import EstadosRoutes from "./routes/estadosRoutes"
import FormasPagamentoRoutes from "./routes/formasPagamentoRoutes"
import FornecedoresRoutes from "./routes/fornecedoresRoutes"
import FuncoesRoutes from "./routes/funcoesRoutes"
import GruposHistoricoPadraoRoutes from "./routes/gruposHistoricoPadraoRoutes"
import GruposRoutes from "./routes/gruposRoutes"
import GruposServicoRoutes from "./routes/gruposServicoRoutes"
import HistoricoPadraoRoutes from "./routes/historicoPadraoRoutes"
import HistoricosRoutes from "./routes/historicosRoutes"
import LocaisRoutes from "./routes/locaisRoutes"
import MarcaEquipamentoRoutes from "./routes/marcaEquipamentoRoutes" // ajustar
import MarcaProdutoRoutes from "./routes/marcaProdutoRoutes" // ajustar
import MarcaVeiculoRoutes from "./routes/marcaVeiculoRoutes" // ajustar
import ModeloEquipamentoRoutes from "./routes/modeloEquipamentoRoutes" // ajustar
import ModeloVeiculoRoutes from "./routes/modeloVeiculoRoutes" // ajustar
import NaturezasOperacao from "./routes/naturezasOperacaoRoutes"
import NCMRoutes from "./routes/ncmRoutes"
import NumeracoesRoutes from "./routes/numeracoesRoutes"
import ObservacoesRoutes from "./routes/observacoesRoutes"
import OrcamentoRoutes from "./routes/orcamentoRoutes" // ajustar
import ordemServico from "./routes/ordemServicoRoutes" // ajustar
import ordemVeiculoRoutes from "./routes/ordemVeiculoRoutes" // ajustar
import PaisesRoutes from "./routes/paisesRoutes"
import PedidosRoutes from "./routes/pedidosRoutes"
import ProdutosRoutes from "./routes/produtosRoutes"
import ProgramasRoutes from "./routes/programasRoutes"
import RelatoriosRoutes from "./routes/relatoriosRoutes"
import RequisicoesRoutes from "./routes/requisicoesRoutes"
import SaldoEstoqueRoutes from "./routes/saldoEstoqueRoutes"
import ServicosRoutes from "./routes/servicosRoutes"
import SetoresServicoRoutes from "./routes/setoresServicoRoutes"
import SubGruposRoutes from "./routes/subGruposRoutes"
import TamanhosRoutes from "./routes/tamanhosRoutes"
import tipoEquipamentoRoutes from "./routes/tipoEquipamentoRoutes" // ajustar
import TiposServicoRoutes from "./routes/tiposServicoRoutes"
import TributacoesRoutes from "./routes/TributacoesRoutes"
import UnidadesRoutes from "./routes/unidadesRoutes"
import UsuariosRoutes from "./routes/usuariosRoutes"
import VeiculosRoutes from "./routes/veiculosRoutes"

////// BAITA MUNDO ///////////////////////////////////////////////////////////////////

import ReservaVeiculosRoutes from "./routes/projetos/baitaMundo/reservaVeiculosRoutes"

////// WHATSAPP /////////////////////////////////////////////////////////////////////

import whatsappRoutes from "./routes/whatsappRoutes"

////// HELP DESK /////////////////////////////////////////////////////////////////////

import solicitacoesRoutes from "./routes/projetos/helpDesk/solicitacoesRoutes"
import setorSolicitacoesRoutes from "./routes/projetos/helpDesk/setorSolicitacoesRoutes"
import situacaoSolicitacoesRoutes from "./routes/projetos/helpDesk/situacaoSolicitacoesRoutes"
import projetoSolicitacoesRoutes from "./routes/projetos/helpDesk/projetoSolicitacoesRoutes"

////// LIPPERT ///////////////////////////////////////////////////////////////////////

import controleServicosRoutes from "./routes/projetos/lippert/controleServicosRoutes"

////// GAUCHINHO /////////////////////////////////////////////////////////////////////

import barrisRoutes from "./routes/projetos/gauchinho/barrisRoutes"
import carregamentoBarrisRoutes from "./routes/projetos/gauchinho/carregamentoBarrisRoutes"
import carregamentoBarrisPRoutes from "./routes/projetos/gauchinho/carregamentoBarrisPRoutes"

import ConcilicaoBancariaRoutes from "./routes/conciliacaoBancariaRoutes"

////// SERVIDOR //////////////////////////////////////////////////////////////////////
import monitoramentoBackupBancos from "./routes/projetos/monitoramentoServidor/monitoramentoServidorRoutes"


const routes = [
  ...AuthenticationRoutes,

  ...homeRoutes,

  ...notFoundRoutes,

  ...conexaoLocalRoutes,

  ...inteligenciaArtificial,

  ...AtualizaBancoRoutes,

  ...GerenciadorEmpresasRoutes,

  ...TestesRoutes,

  ///////////////////////////////////////////////////////////////////////

  ...AgendaServicoRoutes,
  ...BancosRoutes,
  ...BaixaContasPagarRoutes,
  ...CidadesRoutes,
  ...ClientesRoutes,
  ...ColaboradoresRoutes,
  ...ComprasRoutes,
  ...CondicionaisRoutes,
  ...CondicoesVencimentoRoutes,
  ...ConsultaProdutosRoutes,
  ...ContasAReceberRoutes,
  ...CoresRoutes,
  ...CorrecoesEstoqueRoutes,
  ...EmpresasRoutes,
  ...EquipamentoRoutes,
  ...EstadosRoutes,
  ...FormasPagamentoRoutes,
  ...FornecedoresRoutes,
  ...FuncoesRoutes,
  ...GruposHistoricoPadraoRoutes,
  ...GruposRoutes,
  ...GruposServicoRoutes,
  ...HistoricoPadraoRoutes,
  ...HistoricosRoutes,
  ...LocaisRoutes,
  ...MarcaEquipamentoRoutes, // ajustar
  ...MarcaProdutoRoutes, // ajustar
  ...MarcaVeiculoRoutes, // ajustar
  ...ModeloEquipamentoRoutes, // ajustar
  ...ModeloVeiculoRoutes, // ajustar
  ...NaturezasOperacao,
  ...NCMRoutes,
  ...NumeracoesRoutes,
  ...ObservacoesRoutes,
  ...OrcamentoRoutes, // ajustar
  ...ordemServico, // ajustar
  ...ordemVeiculoRoutes, // ajustar
  ...PaisesRoutes,
  ...PedidosRoutes,
  ...ProdutosRoutes,
  ...ProgramasRoutes,
  ...RelatoriosRoutes,
  ...RequisicoesRoutes,
  ...SaldoEstoqueRoutes,
  ...ServicosRoutes,
  ...SetoresServicoRoutes,
  ...SubGruposRoutes,
  ...TamanhosRoutes,
  ...tipoEquipamentoRoutes, // ajustar
  ...TiposServicoRoutes,
  ...TributacoesRoutes,
  ...UnidadesRoutes,
  ...UsuariosRoutes,
  ...VeiculosRoutes,

  ////// BAITA MUNDO ///////////////////////////////////////////////////////////////////

  ...ReservaVeiculosRoutes,

  ////// WHATSAPP /////////////////////////////////////////////////////////////////////

  ...whatsappRoutes,

  ////// HELP DESK /////////////////////////////////////////////////////////////////////

  ...solicitacoesRoutes,
  ...setorSolicitacoesRoutes,
  ...situacaoSolicitacoesRoutes,
  ...projetoSolicitacoesRoutes,

  ////// LIPPERT ///////////////////////////////////////////////////////////////////////

  ...controleServicosRoutes,

  ////// GAUCHINHO /////////////////////////////////////////////////////////////////////

  ...barrisRoutes,
  ...carregamentoBarrisRoutes,
  ...carregamentoBarrisPRoutes,

  ...ConcilicaoBancariaRoutes,
  
  ////// SERVIDOR //////////////////////////////////////////////////////////////////////
  ...monitoramentoBackupBancos
];
const router = new VueRouter({
  mode: 'history',
  routes
});
router.beforeEach((to, from, next) => {
  let token = "";
  document.title = to.meta.title || 'BSSoft'
  const isFavourite = store?.state.favoritos.find(item => item.PROG_DIR_WEB == to.path);
  let itemAtual = routes.find(item => item.path == to.path);
  if (itemAtual?.meta) {
    itemAtual.meta.FAV = isFavourite ? true : false;
  }

  if (VueCookies.get('token')) {
    store.dispatch('saveToken', VueCookies.get('token'));
    token = cript.decryptData(store.state.token);
  }
  if (VueCookies.get('id')) {
    store.dispatch('saveID', VueCookies.get('id'));
    api.defaults.headers.common["clienteid"] = cript.decryptData(store.state.id);
    api_rel.defaults.headers.common["clienteid"] = cript.decryptData(store.state.id);
  }
  if (VueCookies.get('user')) {
    store.dispatch('saveUser', VueCookies.get('user'));
    const userDados = JSON.parse( cript.decryptData( store.state?.user ));
    api.defaults.headers.common["userbs"] = userDados.USR_USUARIO;
    api.defaults.headers.common["telabs"] = to.path;
  }
  if (VueCookies.get('empresa')) {
    store.dispatch('saveEmpresa', VueCookies.get('empresa'));
  }
  store.commit('setNotSaved', false);
  if (to.matched.some(record => record.meta.requiresAuth)) {

    api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    api_rel.defaults.headers.common["Authorization"] = `Bearer ${token}`;

    // Verifica se o usuário está autenticado usando o getter 'isAuthenticated'
    if (!store.getters.isAuthenticated) {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      });
    } else {
      window.scrollTo(0, 0)
      next();
    }
  } else {
    window.scrollTo(0, 0)
    next();
  }
});

export default router