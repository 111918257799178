import api from "@/config/api"
class CompraProdutosGradeController {
  async getLength ( req, res ) {
    try { 
      const total = await api.post( `/compraprodutograde/registros/${ req.ID }/${ req.EMP_ID }/${ req.SERIE }/${ req.FORN_ID }/${ req.PROD_ID }`, req );
      const response = res;
      response.total = total.data[ 0 ].TOT_REGISTROS;
      return response;
    } catch ( error ) {
      console.error( error || "Erro em CompraProdutosGradeController getLength." );
      throw  error
    }
  }
  async index ( req ) {
    try {
      const response = await api.post( `/compraprodutograde/${ req.ID }/${ req.EMP_ID }/${ req.SERIE }/${ req.FORN_ID }/${ req.PROD_ID }`, req );
      if ( response.data.code != 500 ) {
        response.data.forEach(( item, index ) => {
          item.ORDEM = index % 2 == 0 ? 0 : 1;
          item.id = item.CMPG_ID;
        });
      }
      return response.data;
    } catch ( error ) {
      console.error( error || "Erro em CompraProdutosGradeController index." );
      throw error
    }
  }
  async create ( req ) {
    try {
      const response = await api.post( "/compraprodutograde", req );
      return response.data;
    } catch ( error ) {
      console.error( error || "Erro em CompraProdutosGradeController create." );
      throw error
    }
  }
  async update ( req ) {
    try {
      const response = await api.put( `/compraprodutograde/${ req.COMP_ID }/${ req.CMPG_CMPP_COMP_EMP_ID }/${ req.CMPG_CMPP_COMP_SERIE }/${ req.CMPG_CMPP_COMP_FORN_ID }/${ req.CMPG_CMPP_ID }/${ req.CMPG_CMPP_PROD_ID }/${ req.CMPG_PRGR_ID }`, req );
      return response.data;
    } catch ( error ) {
      console.error( error || "Erro em CompraProdutosGradeController update." );
      throw error
    }
  }
  async delete ( req ) {
    try {
      const response = await api.delete( `/compraprodutograde/${ req.ID }/${ req.EMP_ID }/${ req.SERIE }/${ req.FORN_ID }/:seq/:prod_id/:prgr_id`);
      return response.data;
    } catch ( error ) {
      console.error( error || "Erro em CompraProdutosGradeController delete." );
      throw error
    }
  }
  async Stock ( req ) {
    try {
      const response = await api.get( `/compraprodutogradeestoque/${ req.COMP_ID }/${ req.EMP_ID }/${ req.SERIE }/${ req.FORN_ID }/${ req.CMPP_ID }/${ req.PROD_ID }`, req );
      return response.data;
    } catch ( error ) {
      console.error( error || "Erro em CompraProdutosGradeController updateLoop." );
      throw error
    }
  }
}

export default new CompraProdutosGradeController()
