const BaixaContasPagarRoutes = [
  {
    path: "/baixacontaspagar",
    name: "IndexBaixaContasPagar",
    meta: {
      requiresAuth: true,
      title: "Baixa de Contas a Pagar"
    },
    component: () => import( "@/views/baixaContasPagar/IndexBaixaContasPagar.vue" )
  },
  {
    path: "/baixacontaspagar/inserir",
    name: "InsertBaixaContasPagar",
    meta: {
      requiresAuth: true,
      title: "Cadastrar Baixa de Contas a Pagar"
    },
    component: () => import( "@/views/baixaContasPagar/ManageBaixaContasPagar.vue" )
  },
  {
    path: "/baixacontaspagar/alterar",
    name: "AlterBaixaContasPagar",
    meta: {
      requiresAuth: true,
      title: "Editar Baixa de Contas a Pagar"
    },
    component: () => import( "@/views/baixaContasPagar/ManageBaixaContasPagar.vue" )
  }
]

export default BaixaContasPagarRoutes
