const AgendaServicoRoutes = [
  {
    path: "/agendaservico",
    name: "IndexAgendaServico",
    meta: {
      requiresAuth: true,
      title: "Agendamentos de Serviços"
    },
    component: () => import( "@/views/agendaServico/IndexAgendaServico.vue" )
  },
  {
    path: "/agendaservico/inserir",
    name: "InsertAgendaServico",
    meta: {
      requiresAuth: true,
      title: "Cadastro de Agendamento de Serviços"
    },
    component: () => import( "@/views/agendaServico/ManageAgendaServico.vue" )
  },
  {
    path: "/agendaservico/alterar",
    name: "AlterAgendaServico",
    meta: {
      requiresAuth: true,
      title: "Editar Agendamento de Serviços"
    },
    component: () => import( "@/views/agendaServico/ManageAgendaServico.vue" )
  }
]

export default AgendaServicoRoutes
