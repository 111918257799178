<template>
  <v-app>
    <vue-snotify></vue-snotify>
    <nav-bar v-if="!$route.meta.hideNavbar" />
    <v-main class="grey lighten-4">
      <router-view :key="$route.fullPath"></router-view>
    </v-main>
  </v-app>
</template>
<script>
import NavBar from "@/components/layouts/NavBar.vue";
export default {
  components: { NavBar},
  name: "App",
}
</script>
<style>
.app {
  font-family: Inter var,ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
}
.theme--light.v-input--is-disabled input,
.theme--light.v-input--is-disabled textarea {
  color: rgba( 0, 0, 0, 0.87 ) !important;
  cursor: not-allowed;
}
.theme--light.v-input--is-disabled .v-input__control .v-input__slot, .theme--light.v-input--is-disabled .v-input__control .v-input__slot label {
  background: #eee;
  opacity: 1;
  cursor: not-allowed;
}
textarea[ disabled ] {
  background: #eee;
  opacity: 1;
  cursor: not-allowed;
}
.theme--light.v-input--is-readonly .v-input__control .v-input__slot,
.theme--light.v-input--is-readonly .v-input__control .v-input__slot label {
  background: #eee;
  opacity: 1;
  pointer-events: none;
}
.v-btn {
  text-transform: none !important;
}
.v-tab {
  text-transform: none !important;
}
.colortables {
  background-color: #f2f2f2 !important;
}
.colorwhite {
  background-color: #fff !important;
}
.coloryellow {
  background-color: #ffffb3 !important;
}
.coloryellow2 {
  background-color: #E6E600 !important;
}
.colorblue {
  background-color: #9fcfff !important;
}
.colorgreen {
  background-color: #9bff9b !important;
}
.colorgrey {
  background-color: #d1d1d1 !important;
}
.colorpink {
  background-color: #F9DDD9 !important;
}
.colorpurple {
  background-color: #A492F8 !important;
}
.colorred {
  background-color: #FFC9AE !important;
}
.v-list-item--dense, .v-list--dense .v-list-item {
  min-height: 32px;
  font-size: 12px;
}
.v-text-field--filled.v-input--dense.v-text-field--single-line
  > .v-input__control
  > .v-input__slot,
.v-text-field--filled.v-input--dense.v-text-field--outlined
  > .v-input__control
  > .v-input__slot,
.v-text-field--filled.v-input--dense.v-text-field--outlined.v-text-field--filled
  > .v-input__control
  > .v-input__slot,
.v-text-field--full-width.v-input--dense.v-text-field--single-line
  > .v-input__control
  > .v-input__slot,
.v-text-field--full-width.v-input--dense.v-text-field--outlined
  > .v-input__control
  > .v-input__slot,
.v-text-field--full-width.v-input--dense.v-text-field--outlined.v-text-field--filled
  > .v-input__control
  > .v-input__slot,
.v-text-field--outlined.v-input--dense.v-text-field
  > .v-input__control
  > .v-input__slot,
.v-text-field--outlined.v-input--dense.v-text-field--outlined
  > .v-input__control
  > .v-input__slot,
.v-text-field--outlined.v-input--dense.v-text-field--outlined.v-text-field--filled
  > .v-input__control
  > .v-input__slot {
  min-height: 32px !important;
  max-height: 32px !important;
  font-size: 14px;
}
.v-textarea.v-text-field--box .v-text-field__prefix, .v-textarea.v-text-field--box textarea, .v-textarea.v-text-field--enclosed .v-text-field__prefix, .v-textarea.v-text-field--enclosed textarea {
  font-size: 14px;
}
.v-autocomplete.v-select.v-input--is-focused input {
  min-width: 0px !important;
}
.v-messages__message {
  font-size: 11px !important;
  line-height: 11px !important;
  margin-bottom: 13px !important;
}
</style>