import api from "@/config/api"
class CompraDevolucoesController {
  async getLength ( req, res ) {
    try { 
      const total = await api.post( `/compraprodutodevolucao/registros/${ req.EMP_ID }`, req );
      const response = res;
      response.total = total.data[ 0 ].TOT_REGISTROS;
      return response;
    } catch ( error ) {
      console.error( error || "Erro em CompraDevolucoesController.getLength" );
      throw  error
    }
  }
  async index ( req ) {
    try {
      const response = await api.post( `/compraprodutodevolucao/${ req.EMP_ID }`, req );
      if ( response.data.code != 500 ) {
        response.data.forEach(( item, index ) => {
          item.ORDEM = index % 2 == 0 ? 0 : 1;
          item.id = item.CMPP_ID;
          item.text = item.CMPP_DESCRICAO;
        });
      }
      return await this.getLength( req, response.data );
    } catch ( error ) {
      console.error( error || "Erro em CompraDevolucoesController.index" );
      throw error
    }
  }
  async list ( req ) {
   try {
      const response = await api.post( `/compraprodutodevolucao/filtrocontador/${ req.EMP_ID }`, req );
      if ( response.data?.dados?.code != 500 ) {
        response.data?.dados?.forEach(( item, index ) => {
          item.ORDEM = index % 2 == 0 ? 0 : 1;
          item.id = item.CMPP_ID;
          item.text = item.CMPP_DESCRICAO;
        });
      }
      response.data.dados.total = response.data?.count?.TOT_REGISTROS;
      return response.data?.dados;
    } catch ( error ) {
      console.error( error || "Erro em CompraDevolucoesController.list" );
      throw error
    }
  }
  async create ( req ) {
    try {
      const response = await api.post( "/compraprodutodevolucao", req );
      return response.data;
    } catch ( error ) {
      console.error( error || "Erro em CompraDevolucoesController.create" );
      throw error
    }
  }
  async update ( req ) {
    try {
      const response = await api.put( `/compraprodutodevolucao/${ req.CMPP_ID }`, req );
      return response.data;
    } catch ( error ) {
      console.error( error || "Erro em CompraDevolucoesController.update" );
      throw error
    }
  }
  async delete ( req ) {
    try {
      const response = await api.delete( `/compraprodutodevolucao/${ req.CMPP_ID }`);
      return response.data;
    } catch ( error ) {
      console.error( error || "Erro em CompraDevolucoesController.delete" );
      throw error
    }
  }
  async generateInvoicing ( req ) {
    try {
      const response = await api.post( `/compraprodutodevolucao/gerarpedido/${ req.EMP_ID }`, req );
      return response.data;
    } catch ( error ) {
      console.error( error || "Erro em CompraDevolucoesController.generateInvoicing" );
      throw error
    }
  }
}

export default new CompraDevolucoesController()
