import api from '../../config/api';
class ClientesController {
  /* eslint-disable */
  async getLength ( req, res ) {
    try {
      let total = await api.post( `/clientes/registros/${ req.EMP_ID }`, req );
      let response = res;
      response.total = total.data[ 0 ].TOT_REGISTROS;
      return response;
    } catch ( error ) {
      throw error;
    }
  }
  async index ( req ) {
    try {
      let response = await api.post( `/clientes/${ req.EMP_ID }`, req );
      if ( !response.data.msg ) {
        response.data.forEach( ( item, index ) => {
          item.ORDEM = index % 2 === 0 ? 0 : 1;
          item.text = item.CLI_DESCRICAO;
          item.id = item.CLI_ID;
        });
      }
      return await this.getLength( req, response.data );
    } catch ( error ) {
      throw error;
    }
  }
  async created ( req ) {
    try {
      let response = await api.post( '/clientes/', req );
      return response.data;
    } catch ( error ) {
      throw error;
    }
  }
  async updated ( req ) {
    try {
      let response = await api.put( `/clientes/${ req.CLI_ID }/${ req.CLI_EMP_ID }`, req );
      return response.data;
    } catch ( error ) {
      throw error;
    }
  }
  async delete ( req ) {
    try {
      let response = await api.delete( `/clientes/${ req.CLI_ID }/${ req.EMP_ID }`);
      return response.data;
    } catch ( error ) {
      throw error;
    }
  }
  async search ( req ) {
    try {
      let response = await api.get( `/clientes/pesquisar/${ req.FILTRO }/${ req.EMP_ID }`, req );
      if ( !response.data.msg ) {
        response.data.forEach( item => {
          item.text = `${ item.CLI_CGC || item.CLI_CPF } - ${ item.CLI_DESCRICAO }`;
          item.id = item.CLI_ID;
        });
      }
      return response?.data;
    } catch ( error ) {
      throw error;
    }
  }
  async uploadImage ( data ) {
    try {
      let formData = new FormData();
      formData.append( "file", data.IMAGE );
      let response = await api.post( `/clientes/imagem/${ data.ID }/${ data.EMP_ID }`, formData, { headers: { "Content-Type": "multipart/form-data" }});
      return response.data;
    } catch ( error ) {
      throw error;
    }
  }
  async deleteImage ( data ) {
    try {
      let response = await api.delete( `/clientes/imagem/${ data.ID }/${ data.EMP_ID }/${ data.IMAGE }` );
      return response.data;
    } catch ( error ) {
      throw error;
    }
  }
  async getCliente(req) {
    let response = await api.get(`/clientes/visualizar/${req}/1`);
    if(response.data.length > 0){
      response.data.forEach(item => {
        item.text = item.CLI_DESCRICAO
        item.id = item.CLI_ID 
      })
    }
    return response.data;
  }






  async getClienteContato(req) {
    try {
      let response = await api.post(`/clientescontato/${req.CLI_ID}/${req.EMP_ID}`);
      return response.data;
    } catch(error) {
      throw error
    }
  }
  async saveClienteContato(req) {
    let response = await api.post('/clientescontato', req);
    return response.data;
  }
  async deleteClienteContato(req) {
    let response = await api.delete(`/clientescontato/${req.CLI_ID}/${req.EMP_ID}/${req.CONTATO_ID}`);
    return response.data;
  }
  async updateClienteContato(req, form) {
    let response = await api.put(`/clientescontato/${req.CLI_ID}/${req.EMP_ID}/${req.CONTATO_ID}`, form);
    return response.data;
  }


  //referencia
  async getClienteRef(req) {
    let response = await api.post(`/clientesc/${req.CLI_ID}/${req.EMP_ID}`);
    return response.data;
  }
  async addClienteRef(req) {
    let response = await api.post(`/clientesc`, req);
    return response.data;
  }
  async deleteClienteRef(req) {
    let response = await api.delete(`/clientesc/${req.CLI_ID}/${req.CLI_EMP_ID}/${req.REF_ID}`);
    return response.data;
  }



  async getAnexos(req){
    let response = await api.post(`/clientesanexo/${req.CLIA_CLI_ID}/${req.CLIA_CLI_EMP_ID}`);
    return response.data;
  }
  


  async atualizaContas(){
    try {
      let response = await api.get(`/clientes/alinhacontasreceber`);
      return response;
    } catch(error) {
      throw error
    }
  }





  async getContas(req){
    try {
      let response = await api.get(`/contasreceber/cliente/${req.EMP_ID}/${req.CLI_ID}/${req.CLI_TIPO}`);
      return response?.data;
    } catch(error) {
      throw error
    }
  }



  // *** ANEXOS ***
  async uploadAnexos ( data ) {
    try {
      let formData = new FormData();
      formData.append( "file", data.IMAGE );
      formData.append("CLIA_CLI_ID", data.CLIA_CLI_ID);
      formData.append("CLIA_CLI_EMP_ID", data.CLIA_CLI_EMP_ID);
      formData.append("CLIA_USR_ID", data.CLIA_USR_ID);
      formData.append("CLIA_FIL_ID", data.CLIA_FIL_ID);
      formData.append("CLIA_DESCRICAO", data.CLIA_DESCRICAO);
      let response = await api.post( `/clientesanexo`, formData, { headers: { "Content-Type": "multipart/form-data" }});
      return response.data;
    } catch ( error ) {
      throw error;
    }
  }
  async deleteAnexo ( req ) {
    let response = await api.delete( `/clientesanexo/${ req.CLIA_CLI_ID }/${ req.CLIA_CLI_EMP_ID }/${ req.CLIA_ID }/${ req.CLIA_ANEXO }` );
    return response.data;
  }




  async limiteCredito ( req ) {
    let response = await api.get(`/clientes/limitecredito/${ req.id }/${ req.emp_id }`);
    return response.data;
  }
}
export default new ClientesController();