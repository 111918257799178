// Define qual o valor padrão pra chave, reaproveitando o indexamento sem duplicar chaves.
function setDefault(obj, defaultKey = 'default') {
    if (obj && typeof obj === 'object' && obj[defaultKey]) {
        Object.defineProperty(obj, 'toString', {
            get() {
                return this[defaultKey];
            },
            enumerable: false
        });
    }
    return obj;
}
// Lista das chaves e o texto que será exibido.
export const labelDict = {
    ano: {inicial: 'Ano Inicial', final: 'Ano Final'},
    area: 'Área',
    cidade: 'Cidade',
    cliente: 'Cliente',
    data: setDefault({default: 'Data', inicial: 'Data Inicial', final: 'Data Final'}),
    dtaEmissao: setDefault({default: 'Data Emissão', inicial: 'Data Emissão Inicial', final: 'Data Emissão Final'}),
    estado: 'Estado',
    grupo: 'Grupo',
    mes: 'Mês',
    ordem: 'Ordem',
    ordenacao: 'Ordenação',
    ramoAtividade: 'Ramo de Atividade',
    regime: 'Regime',
    representante: 'Representante',
    subGrupo: 'Subgrupo',
    tipo: 'Tipo',
    tipoPessoa: 'Tipo Pessoa',
    tipoRelatorio: 'Tipo de Relatório',
    visualizacao: 'Visualização'
}